<template>
  <div>
 
    <div ref="fullscreenSlide" :class="{ 'fullscreen': isFullscreen }" class="videoContainer">
      <div class="slider-container mb-1 " v-if="images.length > 0" :class="{ 'fullscreen': isFullscreen }" >
        <div class="slider ">
          <!-- <button @click="activeImageView = -1">geri gel</button> -->
     
          <div class="slide row  ">
            <div v-for="(url, index) in images[currentSlide].url " :key='index'
           
              :class="{ 'col-12 p-2 ': activeImageView === index}" class="col ml-2 m-auto  " >
             
              <img v-if="activeImageView === -1 || activeImageView === index" :src="url" alt="logo" id="image" :class="{'activeScreen': images[currentSlide].active === index }"
                @click="getIndex(index)" >
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" :class="{'activeToogle':activeImageView != -1}" v-if="activeImageView === -1 || activeImageView === index" variant="secondary" class="btn-icon btn-fullscreen "
                @click="getIndex(index)">
              <feather-icon icon="Maximize2Icon" size="20" />
             
              </b-button>
              <div v-if="images[currentSlide].active === index ">
                {{ images[currentSlide].tag }}
              </div>
            </div>
          </div>


        </div>
      </div>
   
      <VueSlider v-if="images.length > 0" class="sliderLine  " :data="images" v-model="currentSlide" :max="images.length" :data-value="'id'"
        :data-label="'date'" :hideLabel="true" />
        <div class="row">
          
        </div>
        <div class="row controls w-100 " >
          <b-col md="auto" sm="12" class="text-center pt-1" >
          
            <span id="play" class="d-inline-block" tabindex="0">
              <b-button   v-if="images.length > 0" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
              class="btn-icon" :disabled="autoplay" @click="startAutoplay" >
              <feather-icon icon="PlayIcon" size="20" />
            </b-button>
            </span>
            <b-tooltip target="play">Oynat</b-tooltip>
            <span id="stop" class="d-inline-block" tabindex="0">
            
            <b-button   v-if="images.length > 0" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="warning"
              class="btn-icon ml-1" :disabled="!autoplay" @click="stopAutoplay">
              <feather-icon icon="PauseCircleIcon" size="20" />
            </b-button>
          </span>
          <b-tooltip target="stop">Durdur</b-tooltip>

            <span id="restart" class="d-inline-block" tabindex="0">

            <b-button   v-if="images.length > 0" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger"
              class="btn-icon ml-1" :disabled="!autoplay" @click="resetAutoplay">
              <feather-icon icon="StopCircleIcon" size="20" />
            </b-button>
          </span>
          <b-tooltip target="restart">En başa dön!</b-tooltip>

            <!-- <button @click="stopAutoplay" :disabled="!autoplay">Stop</button> -->
            <!-- <input type="number" v-model="autoplayDelay" @input="setAutoplayDelay" :disabled="autoplay" /> -->
            <!-- <button @click="toggleFullscreen">Toggle Fullscreen</button> -->

          </b-col>

          <!-- range -->
          <b-col md="auto" sm="12" class="text-center" >
            <b-form-group >

              <flat-pickr v-model="rangeDate" class="form-control mt-1" :placeholder="dateText"
                :config="{ dateFormat: 'Y-m-d H:i' }" />
            </b-form-group>
          </b-col>
          <b-col md="auto" sm="12" class="text-center"  >
            <div class="mt-1 " >
              <b-button @click="carculateDateRange" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary">
                Geçmişi Görüntüle
              </b-button>
            </div>
          </b-col>
          <b-col md="auto" sm="12" class="text-center"  style="display: flex;" >
            <b-button v-if="images.length > 0" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="secondary"
              @click="speedMinus" @input="setAutoplayDelay" class="btn-icon mt-1 float-right">
              -
            </b-button>
           <p v-if="images.length > 0" class="mt-2 px-1"> Hız- <strong variant="primary">{{ speedCount }}X</strong> </p>
            <b-button v-if="images.length > 0" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="secondary"
              @click="speed" @input="setAutoplayDelay" class="btn-icon mt-1 float-right">
             +
            </b-button>
          </b-col>

          <b-col md="auto" sm="12" class="text-left"   v-if="images.length > 0">
            <!-- date{{ rangeDate }} -->
            Süre: <span style='color:green' class=" mr-5 text-left">{{ activeTime }}</span><br>
            Toplam Süre: <span style="color:red" class=" text-right">{{ formattedDurationTime }}</span>

          </b-col>
          <b-col md="auto"  sm="12" class="text-right"  v-if="images.length > 0">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="secondary" class="btn-icon ml-1"
              @click="toggleFullscreen">
              <feather-icon icon="MaximizeIcon" size="20" />
            </b-button>
          </b-col>
        
        </div>
        <div class="row pl-3 pt-2"  >
          <p> <span style=" color:#7367F0;font-weight: bold;">Bilgisayar Açılma Tarihi:</span>  {{ images[0].date }}</p>

        </div>
    </div>
  </div>

</template>

<script>
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/antd.css';
import Ripple from "vue-ripple-directive";
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment';


import { BCard, BImg, BNavbar, BNavbarToggle, BCollapse, BTabs, BNavItem, BButton, BRow, BCol, BFormGroup, BPagination, BSpinner,BTooltip,TooltipPlugin } from "bootstrap-vue";

export default {
  components: {
    TooltipPlugin,
    BTooltip,
    VueSlider,
    BCard,
    BImg,
    BNavbar,
    BNavbarToggle,
    BCollapse,
    BTabs,
    BNavItem,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BPagination,
    BSpinner,
    flatPickr


  },
  data() {
    return {
        ///video speed
        currentPage: 1,
      perPage: 1,
      rows: 3,
      speedCount: 1,
      defaultImg: 'https://vagsis.eralpsoftware.net/img/logo.e146a42c.png',
      //
        ///DATEPİCKER ////
        date: null,
      dateDefault: null,
      timePicker: null,
      dateNtim: null,
      multiDate: null,
      rangeDate: null,
      humanDate: null,
      disableDate: null,
      inlineDate: null,
      dateText: 'Tarih Seç',
      /////
       ///VİDEO PLAYER///
       autoplay: false,
      autoplayDelay: 3000,
      autoplayInterval: null,
      
      isFullscreen: false,
      value: 80,
      formattedDuration: null,
      activeTime: '0:00:00',

      ////////
      currentSlide: 0,
      activeImageView: -1,
      //isFullscreen: false,
      // images:[],// [
        // { id: 0, active:0,url: ['https://maminmagazinchik.ru/wp-content/uploads/1/8/5/185a2434f732af747241d50fd1dfa3b1.png', 'https://picsum.photos/200/300?random=2', 'https://picsum.photos/200/300?random=3'], date: '2022-01-01' },
        // { id: 1, active:1,url: ['https://picsum.photos/200/300?random=2', 'https://picsum.photos/200/300?random=3'], date: '2022-01-02' },
        // { id: 2,active:0, url: ['https://picsum.photos/200/300?random=3', 'https://picsum.photos/200/300?random=4'], date: '2022-01-03' },
        // { id: 3,active:1,url: ['https://picsum.photos/200/300?random=4', 'https://picsum.photos/200/300?random=5'], date: '2022-01-04' },
     // ]
    };
  },
  directives: {
    Ripple,
    'b-tooltip': BTooltip
  },
  props: ['images'],

  computed: {
    formattedDurationTime() {
      let durationInSeconds = this.images.length * 10
      const duration = moment.duration(durationInSeconds, 'seconds');
      this.formattedDuration = moment.utc(duration.asMilliseconds()).format('HH:mm:ss');
      return this.formattedDuration
    },
    setImages() {
      return this.images.map((element) => element.url);
    }
  },
  watch: {
    currentSlide(val) {
      this.currentSlide = val
    },
    speedCount: function (newPage) {
      this.autoplayDelay = 3000
      newPage > 1 ? this.autoplayDelay = this.autoplayDelay / (newPage * 2) : ''
      this.setAutoplayDelay()
    },
  },
  mounted(){
    this.carculateDateRange()
  },
  methods: {
    carculateDateRange() {
      
      console.log("girdiii");
      ////dete picker date formater
     // const dateString = '2024-01-03 12:00 to 2024-01-12 12:00';

     let start = '';
let finish = '';

if (this.rangeDate) {
  console.log('tarihi',this.rangeDate);
  // [start, finish] = this.rangeDate.split(' to ');


  const originalDate = moment(this.rangeDate);
const newDate = moment(originalDate).endOf('day'); // Saati 23:59:59 olarak ayarlar

 start = originalDate.format('YYYY-MM-DDTHH:mm:ss');
finish = newDate.format('YYYY-MM-DDTHH:mm:ss');
}
else {
  
  const currentDate = moment().subtract(0, 'day');
  const newDate = moment(currentDate).endOf('day'); // Saati 23:59:59 olarak ayarlar

   start = currentDate.startOf('day').format('YYYY-MM-DDTHH:mm:ss');
  finish = newDate.format('YYYY-MM-DDTHH:mm:ss');
  this.dateText = start.slice(0, 10);

}

console.log('Start:', start);
console.log('Finish:', finish);
this.$emit('getDateScreen', [start, finish]);
this.$emit('postDateScreenToProfileHeader', [start, finish]);

    },
  
    speed() {
      if (this.speedCount === 5) {
        this.speedCount = 1;
      } else {
        this.speedCount++;
      }
    },
    speedMinus() {
      if (this.speedCount >1) {
        this.speedCount--;
      } 
    },
    setAutoplayDelay() {
      clearInterval(this.autoplayInterval);
      this.startAutoplay();
    },
    stopAutoplay() {
      this.autoplay = false;
      clearInterval(this.autoplayInterval);
    },
    resetAutoplay() {
      this.stopAutoplay()
      this.currentSlide = 0
    },
    startAutoplay() {
    //  alert('sdfdf')
   
      this.autoplay = true;
    
      this.autoplayInterval = setInterval(() => {
        this.currentSlide = (this.currentSlide + 1) % this.images.length;
        this.activeTime = this.currentSlide * 10
        let durationInSeconds = this.activeTime
        const duration = moment.duration(durationInSeconds, 'seconds');
        this.activeTime = moment.utc(duration.asMilliseconds()).format('HH:mm:ss');
        if (this.currentSlide === this.images.length - 1 || this.autoplay == false) {
          clearInterval(this.autoplayInterval);
          // this.autoplay = false;
        }
      }, this.autoplayDelay);



    },
    getIndex(index) {
      this.activeImageView = index;
      this.fullscreen = !this.fullscreen; 
      this.fullscreen?this.activeImageView= -1:null
    },
    toggleFullscreen() {

      document.addEventListener('fullscreenchange', () => {
    if (!document.fullscreenElement) {
      this.isFullscreen = false;
    }
  });
      // Tam ekran durumunu değiştirme işlemleri burada yapılır
      // Örneğin, tam ekran durumu toggle edilebilir
      const slideElement = this.$refs.fullscreenSlide;
      if (this.isFullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
        this.isFullscreen = false;
      } else {
        if (slideElement.requestFullscreen) {
          slideElement.requestFullscreen();
        } else if (slideElement.mozRequestFullScreen) {
          slideElement.mozRequestFullScreen();
        } else if (slideElement.webkitRequestFullscreen) {
          slideElement.webkitRequestFullscreen();
        } else if (slideElement.msRequestFullscreen) {
          slideElement.msRequestFullscreen();
        }
        this.isFullscreen = true;
      }
      const elem = document.documentElement;
      if (!this.isFullscreen) {
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen();

        }
     
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      
      }
     
    
    },
  }
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

// #image {
//   width: 100%;
  

// }
.fullscreen {
  .slide img {
    width: 100%;
    height: 80vh !important;
    object-fit: contain;
  }

  .slider-container {
    width: 100%;
   
    
    position: relative;
    overflow: hidden;
    padding-top: 88vh !important;
  }
}
.slider {
  width: 100%;
 
  position: absolute;
  top: 0;
  left: 0;
}

.slide {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.slideOne img {
  width: auto ;
  height: 50vh;
  object-fit: contain;
}
.slide img {
  width: 100%;
  height: 50vh;
  object-fit: contain;
}
.slider-container {
  text-align: center;
  width: 100%;
  height: 0;

  position: relative;
  overflow: hidden;
  padding-top: 88vh;
}
.activeScreen{
  border: 5px solid #7367f0 !important;
}
.controls{
  align-items: center !important;
  justify-content: center !important;
  justify-items: center !important;
  margin-top: 34px;

}
.sliderLine{
  padding: 4px 0px !important;
    width: 80% !important;
    height: 4px !important;
    margin: auto !important;
}
.videoContainer{
  
  .slider-container{
    padding-top: 55vh ;
  }
}
.btn-fullscreen{
  position: absolute !important;
    top: 3% !important;
    right: 4% !important;
}
.activeToogle{
  position: absolute !important;
  top: 10% !important;
  right: 2% !important;
}
[dir] .btn-secondary:focus, [dir] .btn-secondary:active, [dir] .btn-secondary.active {
  background-color: #7367F0 !important;
}
.form-group {
  margin-bottom: 0rem;
}
</style>